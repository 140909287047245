import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/images/hero'
// import AJlogo from '../components/images/aj_logo_top'
// import { Link } from 'gatsby'

const HomePage = ({ data }) => (
  <Layout>
    <SEO title="Home" pathname="/" />
    <p className="text-center">Hello, World.</p>
    <Hero />
    <div id="homepage">
      <h1>
        {' '}
        What&apos;s <span className="angryjenkins">angryjenkins</span>?
      </h1>
      <p>The web portfolio, technical musings and development sandbox of Matthew Martin since 2014.</p>

      <div className="grid_1x2">
        <div>
          <h3>specialties:</h3>

          <ul>
            <li>websites for all platforms</li>
            <li>real-time data applications</li>
            <li>content management systems</li>
          </ul>
        </div>
        <div>
          <h3>interests:</h3>
          <ul>
            <li>modern computing</li>
            <li>data visualization</li>
            <li>sports</li>
          </ul>
        </div>
      </div>

      <h2>Latest Blog</h2>

      <ul id="blog">
        {data.allMarkdownRemark.edges.map(post => (
          <li key={post.node.id}>
            <div className="blog_preview">
              <h3 className="blog_title">
                <Link to={post.node.frontmatter.path}>
                  {post.node.frontmatter.title}
                </Link>
              </h3>
              {/* <h4 className="blog_tagline">{post.node.frontmatter.tagline}</h4> */}
              <i>{post.node.excerpt}</i>
              <p>
                {post.node.frontmatter.tags.map(tag => (
                  <span className="blog_tag" key={tag}>
                    {tag}
                  </span>
                ))}
              </p>
              <p className="blog_credit">
                {post.node.frontmatter.author}, {post.node.frontmatter.date} [
                <Link className="small_link" to={post.node.frontmatter.path}>
                  Read More
                </Link>
                ]
              </p>
            </div>
          </li>
        ))}
      </ul>
      <p>
        Check out the other blogs <Link to="/blog">here.</Link> Stay tuned as
        this site, blog and business grow.
      </p>
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query HomeBlogPreviewQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            author
            date(formatString: "M/D/YYYY")
            path
            tags
            title
          }
          excerpt
          id
        }
      }
    }
  }
`

export default HomePage
